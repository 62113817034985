<template>
  <div class="History">
    <v-row class="History__filter-row">
      <v-col class="mr-auto col-sm-6 col-md-2">
        <app-date-picker
          :range="true"
          v-model="dateRange"
          name="date"
          label=""
        />
      </v-col>

      <v-col class="col-sm-6 col-md-2" v-if="selectedWorkflowHistory">
        <v-card outlined>
          <v-card-text class="primary--text">
            <div class="text-caption text-uppercase">Duration</div>
            <span class="text-h5">{{
              getDuration(
                selectedWorkflowHistory.start,
                selectedWorkflowHistory.finish
              )
            }}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-sm-6 col-md-4" v-if="selectedWorkflowHistory">
        <v-card outlined>
          <v-card-text class="primary--text">
            <div class="text-caption text-uppercase">Start Time</div>
            <span class="text-h5">{{
              formatDate(selectedWorkflowHistory.start)
            }}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-sm-6 col-md-4" v-if="selectedWorkflowHistory">
        <v-card outlined>
          <v-card-text class="primary--text">
            <div class="text-caption text-uppercase">End Time</div>
            <span class="text-h5">{{
              formatDate(selectedWorkflowHistory.finish)
            }}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card outlined>
      <v-overlay absolute :value="historyLoading" :color="'#ffffff'">
        <v-progress-circular
          :size="150"
          :width="3"
          color="primary"
          indeterminate
        />
      </v-overlay>

      <v-row no-gutters class="History__content">
        <v-col class="History__content__storage_list" sm="12" md="3">
          <v-list class="History__item-list">
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item
                v-for="(item, index) in workflowHistory"
                :key="item.id"
                class="History__item"
                two-line
              >
                <v-list-item-icon>
                  <workflow-status-icon
                    :status="item.workflow_status"
                    class="mr-1"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.id" />
                  <v-list-item-subtitle class="mt-2" v-if="item.stats">
                    <p class="text-caption text-uppercase ma-0">Tasks</p>
                    Success
                    <span class="success--text">
                      {{ item.stats.tasks_success }}
                    </span>
                    /
                    <span class="error--text">
                      {{ item.stats.tasks_failed }}
                    </span>
                    Failed
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-btn
                  v-if="item.workflow_status === workflowStatus.PAUSED"
                  @click.stop="handleResumeWorkflow(item.id)"
                  color="primary"
                  outlined
                  small
                  :loading="resumeWorkflowLoading"
                >
                  Resume
                </v-btn>
                <v-btn
                  v-if="item.workflow_status === workflowStatus.PENDING"
                  @click.stop="
                    stopWorkflow({
                      storageId: item.id,
                      workflow_id: item.workflow_id,
                      signal: `STOP`,
                    })
                  "
                  color="primary"
                  outlined
                  small
                  style="margin-right: 4px"
                  :loading="resumeWorkflowLoading"
                >
                  Stop
                </v-btn>
                <v-btn
                  v-if="item.workflow_status === workflowStatus.PENDING"
                  @click.stop="
                    stopWorkflow({
                      storageId: item.id,
                      workflow_id: item.workflow_id,
                      signal: `PAUSE`,
                    })
                  "
                  color="primary"
                  outlined
                  small
                  :loading="resumeWorkflowLoading"
                >
                  Pause
                </v-btn>
                <v-btn
                    v-if="item.workflow_status === workflowStatus.FAILURE || item.workflow_status === workflowStatus.SUCCESS || item.workflow_status === workflowStatus.CANCELLED"
                    @click.stop="updateWorkflowStats({
                      storageId: item.id,
                      workflow_id: item.workflow_id,
                    }, index)"
                    color="primary"
                    outlined
                    :loading="updateStatsLoading"
                    small
                >
                  Update Stats
                </v-btn>
                <v-list-item-icon>
                  <v-icon> mdi-chevron-right </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
            <template v-if="hasMore">
              <v-list-item
                v-intersect="{
                  handler: onIntersect,
                  options: {
                    threshold: [0, 0.5, 1.0],
                  },
                }"
                v-if="enableIntersectionProvider"
                class="py-4"
              />
              <v-list-item
                v-else
                class="History__loading-more-item d-flex justify-center py-4"
              >
                <v-progress-circular indeterminate color="primary" />
              </v-list-item>
            </template>
          </v-list>
        </v-col>

        <v-col sm="12" md="9">
          <v-tabs
            v-if="selectedWorkflowHistory"
            v-model="selectedTab"
            @change="onSelectedTabChange"
          >
            <v-tab v-for="tab in tabs" :key="tab.name" :disabled="tab.disabled">
              {{ tab.name }}
            </v-tab>
            <div v-if="selectedWorkflowHistory?.version" style="padding-left: 20px; padding-top: 13px">
              <span style="font-weight: bold">Workflow version: </span>
              <span>{{ getVersionLabel(selectedWorkflowHistory?.version)}}</span>
            </div>
          </v-tabs>
          <history-node
            v-if="currentTabName === 'all'"
            :selectedItem="selectedItem"
            :selectedWorkflowHistory="selectedWorkflowHistory"
          />
          <failed-history-list
            v-if="currentTabName === 'failed'"
            :selectedWorkflowHistory="selectedWorkflowHistory"
            :selectedItem="selectedItem"
          />
          <retries-history-list
            v-if="currentTabName === 'retried'"
            :selectedWorkflowHistory="selectedWorkflowHistory"
            :selectedItem="selectedItem"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
import { createNamespacedHelpers } from 'vuex';

import AppDatePicker from '@/components/app-date-picker/app-date-picker.vue';
import WorkflowStatusIcon from '@/components/workflow-status-icon/workflow-status-icon.vue';
import HistoryNode from '@/components/history-node/history-node.vue';
import { workflowStatus } from '@/util/workflow-statuses';
import FailedHistoryList from './failed-history/failed-history.vue';
import RetriesHistoryList from './retries-history/retries-history.vue';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

const {
  mapGetters: workflowHistoryGetters,
  mapActions: workflowHistoryActions,
  mapMutations: workflowHistoryMutation,
} = createNamespacedHelpers('workflows/history');

export default {
  name: 'History',
  components: {
    HistoryNode,
    AppDatePicker,
    WorkflowStatusIcon,
    FailedHistoryList,
    RetriesHistoryList,
  },
  data() {
    return {
      dateRange: [moment(new Date()).subtract(30, 'days').toDate(), new Date()],
      localTZ: 'UTC',
      expanded: [],
      headers: [
        { text: 'Start', value: 'start' },
        { text: 'Finish', value: 'finish' },
        { text: 'Duration', value: 'duration' },
        { text: 'Total tasks', value: 'stats.tasks_total' },
        { text: 'Successfull tasks', value: 'stats.tasks_success' },
        { text: 'Task errors', value: 'stats.tasks_failed' },
        { text: 'Success rate', value: 'stats.success_rate' },
        { text: 'Status', value: 'workflow_status' },
      ],
      enableIntersectionProvider: true,
      selectedItem: null,
      workflowStatus,
      selectedTab: 0,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      workflow: 'WORKFLOW',
    }),
    ...workflowHistoryGetters({
      hasMore: 'HAS_MORE',
      workflowHistory: 'AUTOMATION_WORKFLOW_HISTORY',
      historyLoading: 'WORKFLOW_HISTORY_LOADING',
      resumeWorkflowLoading: 'RESUME_WORKFLOW_LOADING',
      workflowHistoryItem: 'WORKFLOW_HISTORY_ITEM',
      updateStatsLoading: 'UPDATE_WORKFLOW_STATS_LOADING',
    }),
    automationId() {
      return this.$route.params.automationId;
    },
    selectedWorkflowHistory() {
      if (this.selectedItem !== null || this.selectedItem !== undefined) {
        return this.workflowHistory[this.selectedItem];
      }

      return null;
    },
    tabs() {
      const results = [
        { name: 'all', disabled: false },
        {
          name: 'failed',
          disabled: false,
        },
        {
          name: 'retried',
          disabled: false,
        },
      ];
      return results;
    },
    currentTabName() {
      const name = this.tabs[this.selectedTab].name;
      return name;
    },
  },
  watch: {
    workflowHistory(newArr, oldArr) {
      if (oldArr.length < newArr.length && !this.enableIntersectionProvider) {
        this.enableIntersectionProvider = true;
      }
    },
    dateRange() {
      this.getHistory();
    },
    selectedWorkflowHistory(newVal, oldVal) {
      if (newVal && newVal.id !== oldVal?.id) {
        const { id } = newVal;

        this.getWorkflowHistoryConnectorsList({
          storage_id: id,
          filter: this.currentTabName,
        });
      }
    },
  },
  methods: {
    ...workflowHistoryActions([
      'getWorkflowHistory',
      'getOneFromWorkflowHistory',
      'getWorkflowHistoryConnectorsList',
      'resumeWorkflow',
      'stopWorkflow',
      'recalcStats',
    ]),
      ...workflowHistoryMutation({
          setWorkflowHistoryItem: 'SET_WORKFLOW_HISTORY_ITEM',
      }),
    handleResumeWorkflow(id) {
      this.resumeWorkflow({ storageId: id })
    },
    getVersionLabel(version) {
      if(!version) {
        return 'none'
      }
      if(version.name) {
        return version.name;
      }
      return moment(version.createdAt).format("YYYY-MM-DD, HH:mm:SS");
    },
    formatDate(date) {
      if (!date) return '';
      else return moment(date).tz(this.localTZ).format('YYYY-MM-DD HH:mm');
    },
    async updateWorkflowStats(data, index) {
      const report = await this.recalcStats(data);
      this.workflowHistory[index].stats = report;
    },
    getDuration(start, finish) {
      if (!start || !finish) return '-';
      else {
        const diff_ms = moment(finish).diff(start);
        return moment.utc(diff_ms).format('HH:mm:ss');
      }
    },
    loadMore() {
      const from = moment(this.dateRange[0]).format('YYYY-MM-DD');
      const to = moment(this.dateRange[1]).format('YYYY-MM-DD');

      if (!from || !to) return;

      this.getWorkflowHistory({
        id: this.automationId,
        skip: this.workflowHistory.length,
        from,
        to,
        tz: this.localTZ,
        skip_pagination: false,
      });
    },
    onIntersect(entries) {
      if (
        this.enableIntersectionProvider &&
        this.hasMore &&
        entries[0].intersectionRatio >= 0.5
      ) {
        this.enableIntersectionProvider = false;
        this.loadMore();
      }
    },
    setLocalTimezone() {
      this.localTZ = moment.tz.guess();
    },
    getHistory(selectedItem) {
      if(selectedItem) {
          this.getOneFromWorkflowHistory({
                workflow_id: this.automationId,
                storage_id: this.workflowHistoryItem.id
          }).then(() => {
              this.selectedItem = 0
              if(this.workflowHistoryItem?.failedTask?.name) {
                  this.selectedTab = 1;
              }
              this.setWorkflowHistoryItem(null)
          })
          return
      }
      const from = moment(this.dateRange[0]).format('YYYY-MM-DD');
      const to = moment(this.dateRange[1]).format('YYYY-MM-DD');

      if (!from || !to) return;

      this.getWorkflowHistory({
        id: this.automationId,
        from,
        to,
        tz: this.localTZ,
        skip_pagination: false,
      })
    },
    onSelectedTabChange() {
      this.getWorkflowHistoryConnectorsList({
        storage_id: this.selectedWorkflowHistory.id,
        filter: this.currentTabName,
      });
    },
  },
  mounted() {
    if(!this.workflowHistoryItem && this.$route.query.storageId) {
      this.setWorkflowHistoryItem({ id: this.$route.query.storageId })
    }
    this.setLocalTimezone();
    this.getHistory(this.workflowHistoryItem);
  },
};
</script>

<style scoped lang="scss">
@import './History.scss';
</style>
